<template>
  <div data-app>
    <!-- 1°first ROW -->
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-card
            title="Picking"
            border-variant="primary"
            header-bg-variant="transparent"
            class="mb-2"
          >
            <b-card-group deck>
              <b-card class>
                <b-row>
                  <b-col cols="6" class="text-center">
                    <span class="badge badge-secondary badge-pill">Código a escanear predeterminado: {{ selectPickingCode }}</span>
                  </b-col>
                  <b-col cols="6">
                    <v-select :items="pickingCodeList" v-model="selectPickingCode" label="Seleccione Tipo de codigo"></v-select>
                  </b-col>
                </b-row>
                <v-divider></v-divider>
                <v-spacer></v-spacer>
                <b-row>
                  <b-col cols="12" class="text-center">
                    <v-btn :disabled="!selectPickingCode" color="green" @click="savePickingConfiguration">Guardar</v-btn>
                  </b-col>
                </b-row>
              </b-card>
            </b-card-group>
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-card
            title="Courier"
            border-variant="warning"
            header-bg-variant="transparent"
            class="mb-2"
          >
            <b-card-group deck>
              <b-card class>
                <v-tooltip top>
                  <template v-slot:activator="{ on}">
                    <span v-on="on" class="badge badge-secondary badge-pill float-right sdw">
                      <i class="fas fa-shipping-fast text-black"></i>
                    </span>
                  </template>
                  <span>Courier</span>
                </v-tooltip>
                <b-row>
                  <b-col cols="6" class="text-center">
                    <!--Tag List-->
                    <span class="badge badge-secondary badge-pill">Courier Predeterminado: {{ selectCarrier }}</span>
                  </b-col>
                  <b-col cols="6">
                    <!--Drop-DownList-->
                    <b-dropdown
                      split
                      split-variant="outline-primary"
                      variant="primary"
                      text="Seleccionar Courier"
                      class="dropdown b-dropdown m-2 show show btn-group"
                      :options="carrierList"
                      v-model="selectCarrier"
                    >
                      <b-dropdown-item
                        v-for="carrier in carrierList"
                        :key="carrier.value"
                        :value="carrier.text"
                        @click="changeCourier(carrier.text)"
                      >{{ carrier.text }}</b-dropdown-item>
                      <!--<b-dropdown-item href="#">99 minutos</b-dropdown-item>
                    <b-dropdown-item href="#">Enviame</b-dropdown-item>
                      <b-dropdown-item href="#">Starken</b-dropdown-item>-->
                    </b-dropdown>

                    <!--Vuetify
                  <v-select
                    @change="verRefSelect"
                    class="ml-2 mr-2"
                    ref="select_carrier"
                    label="Filtro por courier"
                    :options="carrierList"
                    v-model="selectCarrier"
                    ></v-select>-->
                    <!--<b-card-text>Header and footers using props.</b-card-text>
                    <b-button href="#" variant="primary">Go somewhere</b-button>-->
                  </b-col>
                </b-row>
                <v-divider></v-divider>
                <v-spacer></v-spacer>
                <b-row>
                  <b-col cols="6" class="text-center">Courier Predeterminado</b-col>
                  <b-col cols="6">
                    <!--Drop-DownList-->

                    <b-form-group
                      id="fieldset-1"
                      description="input readonly."
                      label="Courier Input"
                      label-for="input-1"
                      class
                    >
                      <b-form-input id="input-1" :value="selectCarrier" readonly trim placeholder="readonly input here.."></b-form-input>
                    </b-form-group>

                    <!--Vuetify
                  <v-select
                    @change="verRefSelect"
                    class="ml-2 mr-2"
                    ref="select_carrier"
                    label="Filtro por courier"
                    :options="carrierList"
                    v-model="selectCarrier"
                    ></v-select>-->
                    <!--<b-card-text>Header and footers using props.</b-card-text>
                    <b-button href="#" variant="primary">Go somewhere</b-button>-->
                  </b-col>
                </b-row>
              </b-card>
            </b-card-group>
            <!--<template v-slot:footer>
              <em>Seleccionar Courier</em>
            </template>-->
          </b-card>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-card
            title="Facturación"
            border-variant="success"
            header-bg-variant="transparent"
            class="mb-2"
          >
            <b-card-group deck>
              <b-card>
                <b-row>
                  <b-col cols="6" class="text-center">
                    <!--Tag List-->
                    <span class="badge badge-secondary badge-pill">Facturación Predeterminado: {{ selectFacturador }}</span>
                  </b-col>
                  <b-col cols="6">
                    <!--Drop-DownList-->
                    <b-dropdown
                      split
                      split-variant="outline-primary"
                      variant="primary"
                      text="Seleccionar Facturador"
                      class="dropdown b-dropdown m-2 show show btn-group"
                      :options="FacturadorList"
                      v-model="selectFacturador"
                    >
                      <!--
                      <b-dropdown-item
                        v-for="facturador in FacturadorList"
                        :key="facturador.value"
                        :value="facturador.value"
                      >{{ facturador.text }}</b-dropdown-item>-->
                      <!---->
                      <b-dropdown-item disabled value="0">Select an Item</b-dropdown-item>
                      <b-dropdown-item href="#">Libre DTE</b-dropdown-item>
                      <b-dropdown-item href="#">MAGENTO</b-dropdown-item>
                      <b-dropdown-item href="#">Facturador</b-dropdown-item>
                    </b-dropdown>

                    <!--Vuetify
                  <v-select
                    @change="verRefSelect"
                    class="ml-2 mr-2"
                    ref="select_carrier"
                    label="Filtro por courier"
                    :options="carrierList"
                    v-model="selectCarrier"
                    ></v-select>-->
                    <!--<b-card-text>Header and footers using props.</b-card-text>
                    <b-button href="#" variant="primary">Go somewhere</b-button>-->
                  </b-col>
                </b-row>
                <v-divider></v-divider>
                <v-spacer></v-spacer>
                <b-row>
                  <b-col cols="6" class="text-center">Facturación Predeterminado</b-col>
                  <b-col cols="6">
                    <b-form-group
                      id="fieldset-2"
                      description="input readonly."
                      label="Facturador Input"
                      label-for="input-2"
                      class
                    >
                      <b-form-input id="input-2"  readonly trim placeholder="readonly input here.."></b-form-input>
                    </b-form-group>

                    <!--Vuetify
                  <v-select
                    @change="verRefSelect"
                    class="ml-2 mr-2"
                    ref="select_carrier"
                    label="Filtro por courier"
                    :options="carrierList"
                    v-model="selectCarrier"
                    ></v-select>-->
                    <!--<b-card-text>Header and footers using props.</b-card-text>
                    <b-button href="#" variant="primary">Go somewhere</b-button>-->
                  </b-col>
                </b-row>
              </b-card>
            </b-card-group>
            <!--<template v-slot:footer>
              <em>Seleccionar Facturador</em>
            </template>-->
          </b-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

export default {
  props: {
    store_id: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({
    selectCarrier: "",
    selectFacturador: "",
    carrierList: [],
    FacturadorList: [],
    selectPickingCode: undefined,
    pickingCodeList: ['sku','ean'],
  }),
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Configuraciones", route: "configuraciones" },
    ]);
    // call carrier Data
    this.fetchCarrierList();
    this.fetchStorePickingCode();
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      let salesChannel = [
        { id: 1, canal: "Jumpseller" },
        { id: 2, canal: "Magento" },
        { id: 3, canal: "Mercado Libre" },
      ];
      this.pageCount = Math.ceil(salesChannel.length / 10);
      this.salesChannel = salesChannel;
    },
    fetchCarrierList() {
      var vm = this;
      this.axios({
        url: "shippings/carriers/get_data_list",
        method: "GET",
      })
        .then((response) => {
          vm.carrierList = response.data;
          console.log(response.data);
          vm.carrierList.push({ text: "Todos", value: 0 });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchDataFacturaciones() {
      var vm = this;
      this.axios({
        url: "invoices/json",
        method: "GET",
        params: {
          "pagination[page]": "1",
          "pagination[pages]": "24",
          "pagination[perpage]": "10",
          "pagination[total]": "234",
          "sort[sort]": "asc",
          "sort[field]": "id",
          query: "",
        },
      })
        .then((response) => {
          vm.FacturadorList = response.data.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    changeCourier(value) {
      //grab some remote data
      this.selectCarrier = value;
      console.log(value);
      /*     try {
                    let response = await this.$http.get('https://www.example.com/api/' + this.ddTestVm.ddTestSelectedOption + '.json');
                    console.log(response.data);
                    this.someOtherProperty = response.data;
                } catch (error) {
                    console.log(error)
                }*/
    },
    fetchStorePickingCode()
    {
      var vm = this;
      this.axios({
        url: 'picking/store_picking_code',
        method: 'GET',
      }).then( response => {
        vm.selectPickingCode = response.data ? response.data : 'sku';
      }).catch( error => {
        console.log(error);
      });
    },
    savePickingConfiguration()
    {
      var vm = this;
      this.axios({
        url: 'picking/store_picking_code',
        method: 'PUT',
        data: {picking_code: this.selectPickingCode}
      }).then( () => {
        vm.$bvToast.toast("Configuracion Picking Actualizada correctamente", {
            title: `Exito`,
            variant: "success",
            solid: true,
            toaster: "b-toaster-bottom-center",
          });
      }).catch( error => {
        console.log(error);
      });
    }
  },
};
</script>